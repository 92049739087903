// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/background.png", import.meta.url);
// Module
var code = `<template>
  <require from="./generate_qr_code.scss"></require>
  <require from="../../../components/card-banka-detailled/card-banka-detailled"></require>
  <require from="../../../components/ValueConverters/currency-denomination"></require>  <require from="../../../components/number-format"></require>
  <section id="generate_qr_code" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="bank.generate_qr_code.generate_qr_code1" if.bind="!focusHide">Générer un code QR</h1>
    </div>
    <div class="flex-grow"></div>
    <div class="canvas">
      <img src="${___HTML_LOADER_IMPORT_1___}" if.bind="!qrcode_canvas">
      <canvas id="qrcode_canvqas" ref="qrcode_canvas" if.bind="qrCodeAmount || qrCodeReference"></canvas>
    </div>
    </div>
    <div class="flex-grow"></div>
    <div class.bind="shaking ? 'shake' : ''">
      <form id="payment_price" class="flex-row"
        class.bind="(isSelectingRecipient || isSelectingSender) ? 'selecting' : ''"
        submit.delegate="gotToQrCodeReceive()">
        <input disabled.bind="locked" id="qrCodeAmount" type="number" step="0.01" min="0" value.bind="qrCodeAmount"
          placeholder="0" required="required" class.bind="isAmountError ? 'error' : ''" autofocus/>
        <p>\${state.wallets[state.selectedAccountIndex].currency}</p>
      </form>
    </div>
    <div class="flex-grow"></div>
    <span class="span_comm" i18n="bank.payment.make_payments_comm" click.delegate="comm = true" if.bind="comm == false">Cliquez ici
      pour ajouter une
      communication</span>
    <div id="communication_type_switch" class="flex-column"
      class.bind="(isSelectingRecipient || isSelectingSender) ? 'selecting' : ''" if.bind="comm == true">
      <div class="bottom flex-row">
        <p class="left" class.bind="!isCommunicationTypeStructured ? 'selected': ''"
          click.delegate="isCommunicationTypeStructured = !isCommunicationTypeStructured">abc</p>
        <p class="right" class.bind="isCommunicationTypeStructured ? 'selected': ''"
          click.delegate="isCommunicationTypeStructured = !isCommunicationTypeStructured">+++</p>
      </div>
    </div>
    <div class="flex-grow"></div>
    <div class="confirm">
      <button class="btn btn-primary" click.delegate="goToQrCodeReceive()" i18n="home.next"
        form="payment_price" disabled.bind="!(qrCodeAmount > 0) || isLoading">Suivant</button>
    </div>
    <div class="spacer-16"></div>
  </section>
</template>
`;
// Exports
export default code;